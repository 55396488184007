import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Config } from 'ngx-myia-core';
import { Observable, Observer } from 'rxjs';
import { IRequestOptions } from './IRequestOptions';
import { JWTService } from './JWTService';

function contentTypeHeader(contentType: string = 'application/json'): HttpHeaders {
    return new HttpHeaders({ 'Content-Type': contentType });
}

@Injectable({ providedIn: 'root' })
export class BackendService {
    public login: EventEmitter<void> = new EventEmitter();
    public accessDenied: EventEmitter<void> = new EventEmitter();

    private readonly _baseUrl: string;

    constructor(private _http: HttpClient, private _jwtService: JWTService) {
        this._baseUrl = Config.get<string>('BASE_API_URL');
    }

    get<T = any>(url: string, options?: IRequestOptions): Observable<T> {
        return this.requestHelper(this.absoluteUrl(url), 'GET', null, options);
    }

    getAndDownload(url: string, contentType: string = 'application/json', noAuth: boolean = false) {
        return this.requestHelper(this.absoluteUrl(url), 'GET', null, {
            noAuth,
            responseType: 'blob',
            headers: contentTypeHeader(contentType)
        });
    }

    post<T>(
        url: string,
        body?: string,
        options?: IRequestOptions
    ): Observable<T> {
        return this.requestHelper(
            this.absoluteUrl(url),
            'POST',
            body || '',
            options
        );
    }

    postJSON<T = void>(
        url: string,
        body: string,
        options?: IRequestOptions
    ): Observable<T> {
        return this.post(url, body, {
            ...options,
            headers: contentTypeHeader()
        });
    }

    postAndDownload(url: string, body?: string) {
        return this.requestHelper(this.absoluteUrl(url), 'POST', body || '', {
            responseType: 'blob',
            headers: contentTypeHeader()
        });
    }

    put<T>(
        url: string,
        body: string,
        options?: IRequestOptions
    ): Observable<T> {
        return this.requestHelper(this.absoluteUrl(url), 'PUT', body, options);
    }

    putJSON<T = void>(
        url: string,
        body: string,
        options?: IRequestOptions
    ): Observable<T> {
        return this.put(url, body, {
            ...options,
            headers: contentTypeHeader()
        });
    }

    delete<T = void>(url: string, options?: IRequestOptions): Observable<T> {
        return this.requestHelper(
            this.absoluteUrl(url),
            'DELETE',
            null,
            options
        );
    }

    patch<T>(
        url: string,
        body: string,
        options?: IRequestOptions
    ): Observable<T> {
        return this.requestHelper(
            this.absoluteUrl(url),
            'PATCH',
            body,
            options
        );
    }

    head<T>(url: string, options?: IRequestOptions): Observable<T> {
        return this.requestHelper(this.absoluteUrl(url), 'HEAD', null, options);
    }

    postFiles(
        url: string,
        files: Array<File>,
        withProgress?: boolean,
        options?: IRequestOptions
    ): Observable<any> {
        return this.makeFileRequest(
            this.absoluteUrl(url),
            files,
            null,
            withProgress,
            options
        );
    }

    absoluteUrl(url: string): string {
        if (url && url.indexOf('://') > 0) {
            return url;
        }
        return `${this._baseUrl}${url}`;
    }

    redirectToLogin() {
        this.login.emit();
    }

    private requestHelper(
        url: string,
        method: string,
        body?: string,
        options?: IRequestOptions
    ): Observable<any> {
        if (options && options.noAuth) {
            // temporary solution how to skip authorization header
            // see https://github.com/angular/angular/issues/18155
            this._jwtService.skipAuthorization(url);
        }
        return this._http.request(method, url, { ...options, body });
    }

    private makeFileRequest(
        url: string,
        files: Array<File>,
        customHeaders?: HttpHeaders,
        withProgress?: boolean,
        options?: IRequestOptions
    ) {
        return new Observable((observer: Observer<any>) => {
            this.doFileRequest(
                observer,
                url,
                files,
                customHeaders,
                withProgress,
                options
            );
        });
    }

    private doFileRequest(
        observer: Observer<any>,
        url: string,
        files: Array<File>,
        customHeaders?: any,
        withProgress?: boolean,
        options?: IRequestOptions
    ) {
        const useOldAPI = Config.get<boolean>('useOldWebAPI', false);
        const formData: any = new FormData();
      // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < files.length; i++) {
            formData.append( useOldAPI ? 'uploads[]' : 'uploads', files[i], files[i].name);
        }
        this.requestHelper(url, 'POST', formData, {
            headers: customHeaders,
            reportProgress: true,
            observe: 'events',
            ...options
        }).subscribe(
            event => {
                if (event.type === HttpEventType.UploadProgress) {
                    if (withProgress) {
                        observer.next({
                            loaded: event.loaded,
                            total: event.total
                        });
                    }
                }
                if (event.type === HttpEventType.Response) {
                    observer.next(
                        withProgress ? { response: event.body } : event.body
                    );
                    observer.complete();
                }
            },
            err => {
                observer.error(err);
            }
        );
    }
}
